import './images-a2.module.css'
import React from "react"
import imagesA2 from './images-a2.module.css'

export default class ImagesA2 extends React.Component {
  render() {
    const color="#0a053d"
    const images=['//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/ed8ef306-5795-4ec4-ad65-7c405ea8c18b_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/0f901f59-365a-48cf-9bdb-745aae9784cc_900x1190.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/1a854aa1-e2f5-42f6-b27c-e94a4890d086_900x637.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/21d92fcc-5ba6-450a-8c7f-43042769f7cf_900x474.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/591f320c-cd80-4882-b8c8-22e1b9eca053_900x600.jpeg']
    return(
      <div className={imagesA2.imagesContainer} style={{background:color}}>
        <img className={imagesA2.bg} src={require('../images/a2/imagesbg.png')}/>
        <div className={imagesA2.imagesTitle}>
          <div>精彩照片</div>
          <div className={imagesA2.imagesTitleBottom}></div>
        </div>
        <div className={imagesA2.imagesList}>
          <div style={{backgroundImage:'url('+images[0]+')'}}></div>
          <div  style={{backgroundImage:'url('+images[1]+')'}}></div>
          <div style={{backgroundImage:'url('+images[2]+')'}} ></div>
          <div  style={{backgroundImage:'url('+images[3]+')'}}></div>
          <div  style={{backgroundImage:'url('+images[4]+')'}}></div>
          <div  style={{backgroundImage:'url('+images[5]+')'}}></div>
          <div  style={{backgroundImage:'url('+images[6]+')'}}></div>
        </div>
      </div>
    )
  }
}