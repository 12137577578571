import newsA2 from './news-a2.module.css'
import React from "react"

export default class NewsA2 extends React.Component {
  render() {
    const color='#0a053d'
    const secondcolor='#5182ff30'
    return (
      <div>
        <div className={newsA2.newsContainer} style={{background:color}}>
          <div className={newsA2.news}>
            <div>
              <div style={{color:'#5182ff'}} className={newsA2.newsTitle}>赛事新闻</div>
              <div style={{color:'#5182ff'}} className={newsA2.newsTitleSmall}>news</div>
              <div className={newsA2.titleBottom}></div>
              <div className={newsA2.newslist}>
                <div className={newsA2.newsItem}><a>1、新闻一新闻一新闻一新闻一</a></div>
                <div className={newsA2.newsItem}><a>1、新闻一新闻一新闻一新闻一</a></div>
                <div className={newsA2.newsItem}><a>1、新闻一新闻一新闻一新闻一</a></div>
              </div>
            </div>
          </div>
          <div className={newsA2.newsImg}>
            <img src={require('../images/a2/news.png')}/>
            <div className={newsA2.newsShadow} style={{background:'#ffffff20'}}></div>
          </div>
        </div>

        <div className={newsA2.newsContainerPhone} style={{background:color}}>
          <div className={newsA2.newsTitlePhone} style={{color:'#5182ff'}}>
            <img alt="hello" src={require('../images/a2/news.png')}/>
            <div>新闻</div>
          </div>
          <div className={newsA2.newsListPhone}>
            <div className={newsA2.newsItem}><a>1、新闻一新闻一新闻一新闻一</a></div>
            <div className={newsA2.newsItem}><a>1、新闻一新闻一新闻一新闻一</a></div>
            <div className={newsA2.newsItem}><a>1、新闻一新闻一新闻一新闻一</a></div>
          </div>
        </div>

        <div className={newsA2.noticesContainer}>
          <div className={newsA2.noticeImg}>
            <img src={require('../images/a2/announcement.png')}/>
            <div className={newsA2.noticeShadow} style={{background:secondcolor}}></div>
          </div>
          <div className={newsA2.notices}>
            <div>
              <div style={{color:'#5182ff'}} className={newsA2.noticeTitle}>赛事公告</div>
                <div style={{color:'#5182ff'}} className={newsA2.noticeTitleSmall}>Notice</div>
              <div className={newsA2.titleBottom}></div>
              <div className={newsA2.noticeslist} >
                <div className={newsA2.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
                <div className={newsA2.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
                <div className={newsA2.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
              </div>
            </div>
          </div>
        </div>

        <div className={newsA2.noticesContainerPhone} >
          <div className={newsA2.noticeTitlePhone} style={{color:'#5182ff'}}>
            <img alt="hello" src={require('../images/a2/announcement.png')}/>
            <div>公告</div>
          </div>
          <div className={newsA2.noticesListPhone}>
            <div className={newsA2.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
            <div className={newsA2.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
            <div className={newsA2.noticeItem}><a>1、公告一公告一公告一公告一</a></div>
          </div>
        </div>
      </div>

    )
  }
}