import React from 'react'
import countdownA2 from './countdown-a2.module.css'
import PropTypes from "prop-types"
export default class CountDownA2 extends React.Component{
  static propTypes={
    count:PropTypes.number.isRequired
  }
  render() {
    let days=Math.floor(this.props.count / 60 % 60)
    let hours=Math.floor(this.props.count / 60 % 60)
    let minutes = Math.floor(this.props.count / 60 % 60)
    let seconds = Math.floor(this.props.count % 60)
    return(
      <div className={countdownA2.countDownContainer}>
        <div className={countdownA2.countDown}>
          <div className={countdownA2.image}>
            <img src={require('../images/a2/countdownimg.png')}/>
          </div>
          <div className={countdownA2.time}>
            <div>
              <div>{days}</div>
              <div className={countdownA2.text}>天</div>
            </div>
            <div>
              <div>{hours}</div>
              <div className={countdownA2.text}>时</div>
            </div>
            <div>
              <div>{minutes}</div>
              <div className={countdownA2.text}>分</div>
            </div>
            <div>
              <div>{seconds}</div>
              <div className={countdownA2.text}>秒</div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}