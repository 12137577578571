import React from "react"

export  default class FooterPosterA2 extends React.Component {
  render() {
    return(
      <div style={{width:'100%'}}>
        <img style={{width:'100%'}} src={require('../images/a2/footerposter.png')}/>
      </div>
    )
  }


}