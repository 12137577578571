import React from "react";
import fastEntryA2 from './fast-entry-a2.module.css'
import MyNavLink from './my-nav-link'

export default class FastEntryA2 extends React.Component{
  render() {
    return(
      <div className={fastEntryA2.fastEntryContainer}>
        <div className={fastEntryA2.left}>
          <div >
            <img src={require('../images/a2/enrollicon.png')}/>
            <div className={fastEntryA2.text}>报名</div>
          </div>
        </div>
        <div className={fastEntryA2.right}>
          <div>
            <img src={require('../images/a2/numicon.png')}/>
            <div className={fastEntryA2.text}>参赛号</div>
          </div>
        </div>
      </div>
    )
  }
}